export class Collapsible extends HTMLElement {
  button: HTMLButtonElement;
  content: HTMLDivElement;
  collapsed = true;
  transitioning = false;

  constructor() {
    super();
    const button = this.querySelector("button");
    const content = this.querySelector(".content");
    if (!button || !content) {
      throw new Error("Collapsible component must have a button and content");
    }
    this.button = button;
    this.content = content as HTMLDivElement;
    this.collapsed = true;
    this.transitioning = false;
    this.button.addEventListener("click", this.toggle.bind(this));

    if (this.dataset.startOpen === "true") {
      this.content.removeAttribute("hidden");
      this.button.setAttribute("aria-expanded", "true");
      this.content.style.height = "auto";
      this.collapsed = false;
    }
  }

  toggle() {
    this.isCollapsed() ? this.open() : this.close();
  }

  isCollapsed() {
    return this.collapsed === true;
  }

  open() {
    if (!this.collapsed || this.transitioning) return;
    this.transitioning = true;
    const eventOptions = {
      detail: {
        id: this.id,
        accordionId: this.dataset.accordionId,
      },
    };
    this.dispatchEvent(new CustomEvent("opening", eventOptions));

    this.content.removeAttribute("hidden");
    this.content.style.height = `${this.content.scrollHeight}px`;
    this.button.setAttribute("aria-expanded", "true");
    this.content.addEventListener(
      "transitionend",
      () => {
        this.content.style.height = "auto";
        this.dispatchEvent(new CustomEvent("opened", eventOptions));
        this.collapsed = false;
        this.transitioning = false;
      },
      { once: true },
    );
  }

  close() {
    if (this.collapsed || this.transitioning) return;
    this.transitioning = true;

    this.content.addEventListener(
      "transitionend",
      () => {
        this.content.setAttribute("hidden", "hidden");
        this.button.setAttribute("aria-expanded", "false");
        this.collapsed = true;
        this.transitioning = false;
      },
      { once: true },
    );
    this.content.style.height = `${this.content.scrollHeight}px`;

    window.setTimeout(() => {
      this.content.style.height = "0px";
    }, 100);
  }
}
