export class VideoScribeModal extends HTMLElement {
  closeButtons: NodeListOf<HTMLButtonElement>;
  dialog: HTMLDialogElement | null;
  continueButton: HTMLButtonElement | null;

  constructor() {
    super();

    this.closeButtons = this.querySelectorAll("[data-close]");
    this.dialog = this.querySelector("[data-dialog]");
    this.continueButton = this.querySelector("[data-continue]");

    this.closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        this.close();
      });
    });

    this.dialog?.addEventListener("close", () => {
      document.body.classList.remove("locked");
      this.dispatchEvent(
        new CustomEvent("close", {
          bubbles: true,
          cancelable: true,
        }),
      );
    });

    this.continueButton?.addEventListener("click", () => {
      this.dispatchEvent(
        new CustomEvent("continue", {
          bubbles: true,
          cancelable: true,
        }),
      );
      this.close();
    });
  }

  open() {
    document.body.classList.add("locked");
    this.dialog?.showModal();
  }

  close() {
    this.dialog?.close();
  }
}
